<template>
  <v-data-table
    must-sort
    fixed-header
    show-expand
    :loading="loading"
    :headers="headers"
    :items="references"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
      itemsPerPageText: `Articles ${ $t('per page') }:`
    }"
    no-data-text="No references found"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col class="shrink">
          <v-btn
            color="success"
            fab
            small
            xv-on="on"
            class="mt-n5"
            xelevation="4"
            @click="$emit('toggle-right-drawer', {}, true)"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #item.code="{ item }">
      <a
        :href="item.url"
        target="_blank"
      >
        {{ item.code_type | uppercase }} {{ item.code }}
      </a>
    </template>
    <template #item.title="{ item }">
      <span v-html="item.title" />
    </template>
    <template #item.publish_date="{item}">
      {{ (item.publish_date) ? $moment(item.publish_date).format("M/D/Y") : "" }}
    </template>
    <template #item.actions="{ item }">
      <v-menu
        bottom
        right
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            small
            ripple
            icon
            plain
            v-on="on"
          >
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list
          class="py-0"
          dense
        >
          <a
            :href="item.url"
            target="_blank"
            style="text-decoration:none"
          >
            <v-list-item
              link
            >
              <v-list-item-action class="mr-4">
                <v-icon small>
                  fal fa-books-medical fa-fw
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Visit the article
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
        </v-list>
      </v-menu>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col>
            <div class="text-body-2">
              {{ item.title }}
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle><v-icon>fal fa-disease</v-icon> Organisms</v-list-item-subtitle>
                <v-list-item-title v-if="item.organisms.length > 0">
                  <v-chip
                    v-for="(organism, index) in item.organisms"
                    :key="index"
                    v-bind="$attrs"
                    outlined
                    :small="true"
                    class="mr-1 mb-1 px-2"
                    x-small
                    v-text="organism.name"
                  />
                </v-list-item-title>
                <v-list-item-title v-else>
                  No associated organisms found
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </td>
    </template>

    <template #page-text="props">
      Showing {{ props.pageStart }} - {{ props.pageStop }} of {{ props.itemsLength }} references
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'references',
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          filter: this.$route.query,
          sortBy: ['title'],
          sortDesc: [false],
        },
        totalItems: null,
        headers: [
          {
            text: 'Code',
            value: 'code',
          },
          {
            text: 'Title',
            value: 'title',
          },
          {
            text: 'Publish Date',
            value: 'publish_date',
          },
          {
            sortable: false,
            align: "right",
            value: "actions",
          },
        ],
        references: [],
      }
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchReferences()
        },
      },
    },
    mounted() {
      this.$root.$on('fetch-references', () => {
        this.fetchReferences()
      })
    },
    methods: {
      fetchReferences () {
        this.loading = true
        const promise = this.axios.get('admin/references', {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'asc' : 'desc',
              search: this.options.search,
            },
              ...this.$route.query,
            },
        })
        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchReferences()
            return
          }
          this.totalItems = response.data.total
          this.options.page = response.data.current_page
          this.options.itemsPerPage = Number(response.data.per_page)
          this.references = response.data.data
        }).catch(error => {
          this.$toast.error(error)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
